@import 'styles/App.scss';

#topBar {
    position: absolute;
    height: 40px;
    left: 0px;
    right: 0px;
    top: 0px;

    background: $top-bar-background-color;
    color: $top-bar-font-color;

    .title {
        position: absolute;
        width: 407px;
        height: 39px;
        left: 38px;
        top: 1px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;   
    }

    :global(.ant-dropdown-trigger) {
        position: absolute;
        right: 10px;
        height: 39px;    
        line-height: 39px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $top-bar-font-color;

        svg {
            width: 13px;
            height: 13px;
            padding-bottom: 3px;
        }
    }
}